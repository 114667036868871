import { Service, Store } from "@retailtune/types/lib/store";
import {
  ARIA_Autocomplete,
  createScrollButton,
  createToastMessagesContainer,
} from "@retailtune/vanilla-ui-core";
import {
  USER_POSITION,
  USER_POSITION_CONSENT,
  api,
  createAutocompleteHandler,
  createDebounceFn,
  createExpirationValue,
  createPosition,
  fetchUserDefaultPosition,
  fetchUserPosition,
  getDevice,
  getExpirable,
  getRadialDistanceFn,
  positionToLatLngLiteral,
  retailTuneAutocompleteHandler,
  setExpirable,
  sortStoresByPriority,
} from "@retailtune/utils";
import { Translations } from "../common/translations";
import {
  CustomRenderer,
  googleAutocompleteHandler,
} from "@retailtune/google-maps-utils";
import { Position } from "@retailtune/types/lib/geolocation";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { createOpeningHoursText } from "../common/openingHours";
import "@retailtune/vanilla-ui-core/styles/aria/autocomplete.css";
import { PredictionData } from "@retailtune/types/lib/autocomplete";
import { Trap_focus } from "../common/utils";
import {
  AnalyticsCategory,
  AnalyticsAction,
} from "@retailtune/types/lib/analytics";
import { style } from "../common/mapStyles";
// TODO recieve from UI/UX

declare const retailtune: {
  api_key: string;
  language: string;
  translations: Translations;
  urlPrefix: string;
};

// Analytics
declare function sendGa4DL(
  category: AnalyticsCategory,
  action: AnalyticsAction,
  label: string,
  storeId: number
): void;

interface PositionObject {
  user: Position;
  default: Position;
  current: Position;
}

interface DirectionObject {
  origin: Position;
  destination: Store | null;
  travelMode: google.maps.TravelMode;
}

// _-_-_-_-_- Global State -_-_-_-_-_

let toast: (message: string) => void;

let addressOriginAutocomplete: ARIA_Autocomplete<PredictionData>;

let position: PositionObject;
let direction: DirectionObject;

let stores: Store[];
let storeMarkers: google.maps.Marker[] = [];
let visibleStores: Store[];
let lastSelectedStore: Store | null;
let storeIdToMarkerMap = new Map<number, google.maps.Marker>();
let storeCountriesSet = new Set<string>();

const searchParams = new URLSearchParams(window.location.search);
let deviceType = getDevice();

let map: google.maps.Map;
let userMarker: google.maps.Marker;
let infoWindow: google.maps.InfoWindow;
let directionsService: google.maps.DirectionsService;
let directionsRenderer: google.maps.DirectionsRenderer;
let directionsResult: google.maps.DirectionsResult;
let destinationMarker: google.maps.Marker | null;
let travelModes: google.maps.TravelMode[];
let markerClusterer: MarkerClusterer;

// Trap focus Class instances
let directionsPaneFocusObj: Trap_focus;
let consentModalFocusObj: Trap_focus;

async function main() {
  // _-_-_-_-_- Set-Ups -_-_-_-_-_

  api.setOrigin({ type: "production" });

  const { api_key, language, translations, urlPrefix } = retailtune;

  const [genericData, defaultPosition] = await Promise.all([
    api.fetchPageData(api_key, language),
    fetchUserDefaultPosition(api_key),
  ]);

  stores = genericData.stores;

  // Position state setup
  const userPosition = getExpirable<Position>(USER_POSITION) ?? defaultPosition;
  position = {
    current: userPosition,
    default: defaultPosition,
    user: userPosition,
  };

  const storeTypeSet = new Set<string>();

  for (const store of stores) {
    if (store.status === "closed") continue;

    // create store marker and add it to marker map
    const storePosition = { lat: store.latitude, lng: store.longitude };
    const marker = new google.maps.Marker({
      position: storePosition,
      icon: `${urlPrefix}/img/pin/pin-store.svg`,
      map,
    });
    marker.addListener("click", () => {
      infoWindow.setContent(createInfoWindow(store));
      infoWindow.open(map, marker);

      lastSelectedStore = store;
      google.maps.event.trigger(map, "bounds_changed");

      // Analytics
      sendGa4DL(
        "Store",
        "Click",
        `StoreClickMap-${store.city} ${store.address1}-${deviceType}-${language}`,
        store.id
      );
    });
    storeIdToMarkerMap.set(store.id, marker);

    // add a unique store country to storeCountriesSet
    storeCountriesSet.add(store.country.alpha2);

    // add a unique store type to storeTypeSet
    storeTypeSet.add(store.storeTypes[0].id);
  }

  storeMarkers = Array.from(storeIdToMarkerMap.values());

  // _-_-_-_-_- Dynamic Components -_-_-_-_-_

  // Map state setup
  const mapContainerEl = document.getElementById("rt_map")!;
  map = new google.maps.Map(mapContainerEl, {
    zoom: 7,
    center: positionToLatLngLiteral(position.current),
    styles: style,
  });

  let mapDebounce = createDebounceFn()(200);
  map.addListener("bounds_changed", () => {
    mapDebounce(() => {
      getStoreCards(map.getBounds()!);

      deviceType = getDevice();
      if (deviceType === "desktop" && lastSelectedStore) {
        const selectedStoreCardEl = document.getElementById(
          `rt_${lastSelectedStore.id}`
        )!;
        selectedStoreCardEl.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
        lastSelectedStore = null;
      }
    });
  });

  infoWindow = new google.maps.InfoWindow();

  userMarker = new google.maps.Marker({
    position: positionToLatLngLiteral(position.current),
    icon: `${urlPrefix}/img/pin/pin-user.svg`,
    map,
  });

  markerClusterer = new MarkerClusterer({
    map,
    markers: storeMarkers,
    renderer: new CustomRenderer({
      clusterIcon: `${urlPrefix}/img/pin/cluster.svg`,
    }),
  });

  // Directions state setup
  travelModes = [
    google.maps.TravelMode.WALKING,
    google.maps.TravelMode.DRIVING,
    google.maps.TravelMode.TRANSIT,
  ];

  direction = {
    origin: position.user,
    destination: null,
    travelMode: google.maps.TravelMode.DRIVING,
  };

  directionsService = new google.maps.DirectionsService();
  directionsRenderer = new google.maps.DirectionsRenderer({
    map,
    polylineOptions: {
      strokeOpacity: 1,
    },
    markerOptions: { visible: false },
  });

  new ARIA_Autocomplete({
    anchor: "rt_main_autocomplete",
    handlers: {
      predictionsSearch: createAutocompleteHandler(
        retailTuneAutocompleteHandler(api_key, {
          language,
          countries: Array.from(storeCountriesSet),
        }),
        googleAutocompleteHandler()
      ),
      predictionsSelection: (prediction) => {
        clearDrivingDirections();
        position.current = createPosition({
          latitude: prediction.latitude,
          longitude: prediction.longitude,
        });
        const currentLatLngLiteralPosition = positionToLatLngLiteral(
          position.current
        );
        map.setCenter(currentLatLngLiteralPosition);
        map.setZoom(11);
        userMarker.setPosition(currentLatLngLiteralPosition);

        // Analytics
        sendGa4DL("StoreLocator", "Click", "FreeSearch", 0);
      },
    },
    id: "main",
    translations: {
      inputLabel: translations.k_search,
      inputPlaceholder: translations.k_autocomplete_placeholder,
      zeroResultsMessage: translations.k_autocomplete_zero_results_message,
    },
    selectIcon: {
      ariaLabel: translations.k_search,
      url: `${urlPrefix}/img/icon/search.svg`,
    },
  });

  addressOriginAutocomplete = new ARIA_Autocomplete({
    anchor: "rt_address_origin",
    handlers: {
      predictionsSearch: createAutocompleteHandler(
        retailTuneAutocompleteHandler(api_key, {
          language,
          countries: Array.from(storeCountriesSet),
        }),
        googleAutocompleteHandler()
      ),
      predictionsSelection: (prediction) => {
        position.current = createPosition({
          latitude: prediction.latitude,
          longitude: prediction.longitude,
        });
        direction.origin = position.current;
        navigateUser();

        // Analytics
        sendGa4DL("StoreLocator", "Click", "FreeSearchDirections", 0);
      },
    },
    id: "address_origin",
    translations: {
      inputLabel: translations.k_search,
      inputPlaceholder: translations.k_autocomplete_placeholder,
      zeroResultsMessage: translations.k_autocomplete_zero_results_message,
    },
  });

  [toast] = createToastMessagesContainer({
    anchor: "rt_map_list_container",
    position: "top-right",
  });

  // store list SCT button
  createScrollButton({
    anchorEl: "rt_list_container",
    scrollingEl: "rt_list_container",
    id: "rt_store_list_scroller",
  });

  // directions pane SCT button
  createScrollButton({
    anchorEl: "rt_directions_pane",
    scrollingEl: "rt_directions_pane",
    id: "rt_directions_pane_scroller",
  });

  // _-_-_-_-_- Static Components -_-_-_-_-_

  const travelModescontainer = document.getElementById("rt_travel_modes")!;
  for (let travelMode of travelModes) {
    const cls =
      "rt-travel-mode " +
      (travelMode === direction.travelMode ? "rt-travel-mode--selected" : "");
    travelModescontainer.appendChild(
      <>
        <button
          aria-lable={travelMode.toLowerCase()}
          class={cls}
          travel-mode={travelMode.toLowerCase()}
          onclick={() => {
            direction.travelMode = travelMode;
            navigateUser();
          }}
        >
          <img
            class="rt-travel-mode__icon"
            src={`${urlPrefix}/img/icon/${travelMode.toLowerCase()}-inactive.svg`}
            alt={`${travelMode} icon`}
          />
          <img
            class="rt-travel-mode__icon"
            src={`${urlPrefix}/img/icon/${travelMode.toLowerCase()}-active.svg`}
            alt={`${travelMode} icon`}
          />
        </button>
      </>
    );
  }

  // Actually run application
  geolocateUser();

  // checking for searchParams
  const storeCodeQuery = searchParams.get("code");
  direction.destination =
    stores.find((store) => store.storeCode === storeCodeQuery) ?? null;

  if (direction.destination) {
    initDirections();
  }

  // _-_-_-_-_- DOM interactions -_-_-_-_-_

  const geolocalizeMeBtn = document.getElementById(
    "rt_find_closest_store_btn"
  )!;
  geolocalizeMeBtn.onclick = () => {
    clearDrivingDirections();
    geolocateUser();

    // Analytics
    sendGa4DL("StoreLocator", "Click", "FindNearestStore", 0);
  };

  const backToNearestStoreButt = document.getElementById(
    "rt_back_to_nearest_store"
  )!;
  backToNearestStoreButt.addEventListener("click", () => {
    if (direction.destination) {
      navigateUser();
    }
    let mapZoom = map.getZoom();
    let mapBounds = map.getBounds();
    while (
      mapBounds &&
      mapZoom &&
      getStoreCards(mapBounds) === 0 &&
      mapZoom !== 0
    ) {
      map.setZoom(--mapZoom);
      mapBounds = map.getBounds()!;
    }
  });

  const directionsPaneCloseButt = document.getElementById(
    "rt_directions_pane_close_btn"
  )!;
  directionsPaneCloseButt.addEventListener("click", () => {
    clearDrivingDirections();
    directionsPaneFocusObj.untrap();
  });

  const positionConsentModalCloseBtn = document.getElementById(
    "rt_modal_close_butt"
  )!;
  positionConsentModalCloseBtn.onclick = () => showConsentModal(false);

  const positionConsentModalApprovalButt = document.getElementById(
    "rt_position_consent_modal_approval"
  )!;
  positionConsentModalApprovalButt.onclick = () => {
    setExpirable(USER_POSITION_CONSENT, {
      value: JSON.stringify(true),
      expiration: createExpirationValue(1, "years"),
    });
    geolocateUser();
    showConsentModal(false);

    // Analytics
    sendGa4DL("StoreLocator", "Geo", "Agree", 0);
  };

  const positionConsentModalRejectionButt = document.getElementById(
    "rt_position_consent_modal_rejection"
  )!;
  positionConsentModalRejectionButt.onclick = () => {
    showConsentModal(false);

    // Analytics
    sendGa4DL("StoreLocator", "Geo", "Disagree", 0);
  };

  window.onresize = () => {
    const newDeviceType = getDevice();
    if (
      direction.destination &&
      newDeviceType !== "desktop" &&
      deviceType === "desktop"
    ) {
      clearDrivingDirections();
    }
    if (deviceType !== newDeviceType) {
      deviceType = newDeviceType;
    }
  };
}

function getStoreCards(mapBounds: google.maps.LatLngBounds) {
  const { urlPrefix, translations, language } = retailtune;

  visibleStores = [];

  for (const store of stores) {
    if (store.status === "closed") continue;

    const storeBounds = { lat: store.latitude, lng: store.longitude };
    const getDistance = getRadialDistanceFn(
      position.current.latitude,
      position.current.longitude
    );
    if (mapBounds.contains(storeBounds)) {
      store.distance = getDistance(store.latitude, store.longitude);
      visibleStores.push(store);
    }
  }
  visibleStores.sort(sortStoresByPriority);

  const backToNearestStoreButt = document.getElementById(
    "rt_back_to_nearest_store"
  )!;
  if (visibleStores.length === 0) {
    backToNearestStoreButt.classList.add("rt-back-to-nearest-store--visible");
  } else {
    backToNearestStoreButt.classList.remove(
      "rt-back-to-nearest-store--visible"
    );
  }

  const currentDayIndex = new Date().getDay();
  let dayIndex: number;
  if (currentDayIndex === 0) {
    dayIndex = 6;
  } else {
    dayIndex = currentDayIndex - 1;
  }

  const storeListContainer = document.getElementById("rt_store_list")!;
  storeListContainer.replaceChildren(
    <>
      {visibleStores.map((store, storeIndex) => (
        <li
          id={`rt_${store.id}`}
          onclick={(e: Event) => {
            e.stopPropagation();

            if (deviceType === "desktop") {
              infoWindow.setContent(createInfoWindow(store));
              infoWindow.open(map, storeIdToMarkerMap.get(store.id));
              map.setCenter({
                lat: store.latitude,
                lng: store.longitude,
              }),
                map.setZoom(13);
            }

            // Analytics
            sendGa4DL(
              "Store",
              "Click",
              `StoreClickListing-${store.city} ${store.address1}-${deviceType}-${language}`,
              store.id
            );
          }}
        >
          <article tabindex="0" class="rt-store-card">
            {store.status === "will_open_soon" && (
              <span class="rt-store-card__next-opening | rt-next-opening">
                {translations.k_opening_soon}
              </span>
            )}
            <div class="rt-store-card__heading">
              <span class="rt-store-card__name">{store.name}</span>
            </div>
            <div class="rt-store-card__info">
              <span class="rt-store-card__address">
                {store.address1}, {store.city}, {store.postalCode},{" "}
                {store.country.alpha2.toUpperCase()}
              </span>
              <ul class="rt-store-card__contacts">
                {store.phone && (
                  <li>
                    <a
                      onclick={(e: Event) => {
                        e.stopPropagation();
                        // Analytics
                        sendGa4DL(
                          "Store",
                          "Click",
                          `PhoneClickListing-${store.city} ${store.address1}-${deviceType}-${language}`,
                          store.id
                        );
                      }}
                      href={`tel:${store.phone}`}
                    >
                      <img
                        aria-hidden="true"
                        src={`${urlPrefix}/img/icon/phone.svg`}
                        alt="phone icon"
                        width="9"
                        height="15"
                      />
                      <span>{store.phone}</span>
                    </a>
                  </li>
                )}
                {store.email && (
                  <li>
                    <a href={`mailto:[${store.email}]`}>
                      <img
                        aria-hidden="true"
                        src={`${urlPrefix}/img/icon/mail.svg`}
                        alt="mail icon"
                        width="9"
                        height="15"
                      />
                      <span>{store.email}</span>
                    </a>
                  </li>
                )}
              </ul>
              <span class="rt-store-card__hours-today">
                {createOpeningHoursText(language, store, translations)}
              </span>
            </div>
            <div class="rt-store-card__cta">
              <a
                class="rt-store-card__details | rt-btn rt-btn--primary"
                onclick={(e: Event) => {
                  e.stopPropagation();
                  // Analytics
                  sendGa4DL(
                    "Store",
                    "Click",
                    `DetailsClickListing-${store.city} ${store.address1}-${deviceType}-${language}`,
                    store.id
                  );
                }}
                href={`${urlPrefix}/${language}${store.storeLink.path}`}
              >
                {translations.k_info_and_promotions}
              </a>
              <button
                class="rt-store-card__directions | rt-btn rt-btn--secondary"
                onclick={(e: Event) => {
                  e.stopPropagation();
                  direction = {
                    destination: store,
                    origin: position.user,
                    travelMode: google.maps.TravelMode.DRIVING,
                  };
                  initDirections();

                  // Analytics
                  sendGa4DL(
                    "Store",
                    "Click",
                    `DirectionsClickListing-${store.city} ${store.address1}-${deviceType}-${language}`,
                    store.id
                  );
                }}
              >
                {translations.k_bring_me_here}
              </button>
            </div>
          </article>
        </li>
      ))}
    </>
  );
  return visibleStores.length;
}

async function geolocateUser() {
  if (getExpirable(USER_POSITION_CONSENT)) {
    position.user = await fetchUserPosition(position.default);

    switch (position.user.type) {
      case "html5":
        // Analytics
        position.user.origin === "fetched"
          ? sendGa4DL("StoreLocator", "Geo", "Success", 0)
          : sendGa4DL("StoreLocator", "Geo", "SuccessCookies", 0);
        break;
      case "ip":
        toast(retailtune.translations.k_geolocation_toast_message);

        // Analytics
        position.user.origin === "fetched"
          ? sendGa4DL("StoreLocator", "GeoIP", "Success", 0)
          : sendGa4DL("StoreLocator", "GeoIP", "SuccessCookies", 0);
        break;
      case "default":
        toast(retailtune.translations.k_geolocation_toast_message);

        // Analytics
        sendGa4DL("StoreLocator", "GeoDefault", "Success", 0);
        break;
    }

    position.current = position.user;
    let center = positionToLatLngLiteral(position.current);
    map.setCenter(center);
    const { latitude: defLat, longitude: defLng } = position.default;
    const { latitude: usrLat, longitude: usrLng } = position.user;
    const isDefault = defLat === usrLat && defLng === usrLng;
    map.setZoom(isDefault ? 7 : 10);

    direction.origin = position.user;
    userMarker.setPosition(center);
  } else {
    showConsentModal(true);
    map.setZoom(7);
  }
}

function showConsentModal(shouldShow: boolean) {
  const positionConsentModalContainer = document.getElementById(
    "rt_position_consent_modal"
  )! as HTMLDialogElement;
  if (shouldShow) {
    positionConsentModalContainer.showModal();
    consentModalFocusObj = new Trap_focus(
      positionConsentModalContainer,
      document.body
    );
    setTimeout(() => {
      consentModalFocusObj.trap();
    }, 100);
  } else {
    consentModalFocusObj.untrap();
    positionConsentModalContainer.close();
  }
}

function createInfoWindow(store: Store) {
  const { translations, urlPrefix, language } = retailtune;
  return (
    <article class="rt-iw">
      {store.status === "will_open_soon" && (
        <span class="rt-iw__next-opening | rt-next-opening">
          {translations.k_opening_soon}
        </span>
      )}
      <span class="rt-iw__name">{store.name}</span>
      <div class="rt-iw__info">
        <span class="rt-iw__address">
          {store.address1}, {store.city}, {store.postalCode},{" "}
          {store.country.alpha2.toUpperCase()}
        </span>
        <ul class="rt-iw__contacts">
          {store.phone && (
            <li>
              <a
                href={`tel:${store.phone}`}
                onclick={(e: Event) => {
                  e.stopPropagation();
                  // Analytics
                  sendGa4DL(
                    "Store",
                    "Click",
                    `PhoneClickMap-${store.city} ${store.address1}-${deviceType}-${language}`,
                    store.id
                  );
                }}
              >
                <img
                  aria-hidden="true"
                  src={`${urlPrefix}/img/icon/phone.svg`}
                  alt="phone icon"
                  width="9"
                  height="15"
                />
                <span>{store.phone}</span>
              </a>
            </li>
          )}
          {store.email && (
            <li>
              <a href={`mailto:[${store.email}]`}>
                <img
                  aria-hidden="true"
                  src={`${urlPrefix}/img/icon/mail.svg`}
                  alt="mail icon"
                  width="9"
                  height="15"
                />
                <span>{store.email}</span>
              </a>
            </li>
          )}
        </ul>
      </div>
      <div class="rt-iw__cta">
        <a
          class="rt-iw__details | rt-btn rt-btn--primary"
          href={`${urlPrefix}/${language}${store.storeLink.path}`}
        >
          {translations.k_info_and_promotions}
        </a>
        <button
          class="rt-iw__directions | rt-btn rt-btn--secondary"
          onclick={(e: Event) => {
            e.stopPropagation();
            direction = {
              destination: store,
              origin: position.user,
              travelMode: google.maps.TravelMode.DRIVING,
            };
            initDirections();

            // Analytics
            sendGa4DL(
              "Store",
              "Click",
              `DirectionsClickMap-${store.city} ${store.address1}-${deviceType}-${language}`,
              store.id
            );
          }}
        >
          {translations.k_bring_me_here}
        </button>
      </div>
    </article>
  );
}

function initDirections() {
  const { destination } = direction;
  if (!destination) {
    return;
  }

  if (deviceType !== "desktop") {
    window.open(destination.googleMapsLink, "_blank");
    return;
  }

  const directionsPaneContainer =
    document.getElementById("rt_directions_pane")!;

  directionsPaneContainer.classList.add("rt-directions-pane--visible");

  const storeListScroller = document.getElementById("rt_store_list_scroller")!;
  storeListScroller.classList.remove("rt-back-to-top--visible");

  const destinationAddressEl = document.getElementById(
    "rt_address_destination"
  )!;
  destinationAddressEl.textContent = destination.name;

  const instructionsLableEl = document.getElementById("rt_instructions_label")!;
  instructionsLableEl.textContent = destination.name;
  markerClusterer.clearMarkers();

  if (!destinationMarker) {
    destinationMarker = new google.maps.Marker({
      position: { lat: destination.latitude, lng: destination.longitude },
      icon: `${retailtune.urlPrefix}/img/pin/pin-store.svg`,
      map,
    });
  }

  infoWindow.setContent(createInfoWindow(destination));
  infoWindow.open(map, destinationMarker);

  directionsPaneFocusObj = new Trap_focus(
    directionsPaneContainer,
    document.body
  );
  setTimeout(() => {
    directionsPaneFocusObj.trap();
  }, 100);

  // waiting for navigateUser to be resolved
  navigateUser();
}

async function navigateUser() {
  const { destination, travelMode } = direction;

  if (!destination) {
    return;
  }

  const { language, translations } = retailtune;

  const travelModesContainer = document.getElementById("rt_travel_modes")!;
  for (let el of travelModesContainer.children) {
    el.getAttribute("travel-mode") === direction.travelMode.toLowerCase()
      ? el.classList.add("rt-travel-mode--selected")
      : el.classList.remove("rt-travel-mode--selected");
  }

  try {
    directionsResult = await directionsService.route({
      destination: { lat: destination.latitude, lng: destination.longitude },
      origin: positionToLatLngLiteral(direction.origin),
      travelMode: travelMode,
      language: language,
    });

    userMarker.setPosition(positionToLatLngLiteral(direction.origin));

    if (directionsRenderer) {
      directionsRenderer.setDirections(directionsResult);
    }

    if (directionsRenderer !== undefined) {
      const { steps } = directionsResult.routes[0].legs[0];

      const directionInstructionsList = document.getElementById(
        "rt_instructions_list"
      )!;

      for (const instruction of directionInstructionsList.children) {
        directionsPaneFocusObj.removeFocusableElement(
          instruction as HTMLElement
        );
      }

      directionInstructionsList.replaceChildren(
        <>
          {steps.map((step, index) => (
            <li class="rt-instructions-item" tabIndex="0">
              <div>
                <strong>{++index}.</strong>
                <span innerHTML={step.instructions}></span>
              </div>
            </li>
          ))}
        </>
      );

      for (const instruction of directionInstructionsList.children) {
        directionsPaneFocusObj.addFocusableElement(
          instruction as HTMLElement,
          directionsPaneFocusObj.focusableElements.length - 1
        );
      }
    }
  } catch (error) {
    toast(translations.k_navigation_toast_message);
    console.warn(error);
  }
}

function clearDrivingDirections() {
  const directionsPaneContainer =
    document.getElementById("rt_directions_pane")!;
  directionsPaneContainer.classList.remove("rt-directions-pane--visible");

  const directionsPaneScroller = document.getElementById(
    "rt_directions_pane_scroller"
  )!;
  directionsPaneScroller.classList.remove("rt-back-to-top--visible");

  direction.destination = null;
  direction.travelMode = google.maps.TravelMode.DRIVING;
  direction.origin = position.user;

  markerClusterer.addMarkers(storeMarkers);
  userMarker.setPosition(positionToLatLngLiteral(position.user));
  directionsRenderer.set("directions", null);

  if (destinationMarker) {
    destinationMarker.setMap(null);
    destinationMarker = null;
  }

  addressOriginAutocomplete.resetAutocomplete();
}

let i = setInterval(() => {
  try {
    if (!retailtune || !google || !sendGa4DL) {
      throw new Error("Neccessary objects are missing to run the application.");
    }
    clearInterval(i);
    main();
  } catch (error) {
    console.log(error);
  }
}, 50);
