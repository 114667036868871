import { OpeningHoursPair, Store } from "@retailtune/types/lib/store";
import { Translations } from "./translations";
import { from24to12Hours } from "@retailtune/utils";
import { OpeningTimeResult } from "@retailtune/utils/types/time";

export interface OpeningTimeProps {
  dayIndex: number;
  store: Store;
  format: "24h" | "12h";
  hoursSeparator?: string;
  openingsSeparator?: string;
}

export function createOpeningHoursText(
  lang: string,
  store: Store,
  translations: Translations,
  index?: number
) {
  const currentDate = new Date();
  let targetDayDate: Date;

  if (index) {
    targetDayDate = new Date(
      currentDate.getTime() -
        (currentDate.getDay() - index) * 24 * 60 * 60 * 1000
    );
  } else {
    // create UTC time
    const currentUTCtime =
      currentDate.getTime() + currentDate.getTimezoneOffset() * 60 * 1000;
    index = new Date(currentUTCtime).getDay();
    // time local to store timezone
    const storeTime = new Date(currentUTCtime + store.hourOffset * 1000);
    targetDayDate = storeTime;
  }

  // create dayname
  const dayNameStr = targetDayDate.toLocaleString(lang, { weekday: "long" });
  const dayNameStr_upperCase =
    dayNameStr.charAt(0).toUpperCase() + dayNameStr.slice(1);

  const openingTimeResult = getStoreOpeningTime({
    dayIndex: index === 7 ? 0 : index,
    store,
    format: "24h",
    openingsSeparator: "|",
  });

  let openingTimeBlock: HTMLElement | null = null;
  switch (openingTimeResult.status) {
    case "ok":
      openingTimeBlock = (
        <>
          <span class="rt-dayname">{dayNameStr_upperCase}</span>
          <time class="rt-opening-time">{openingTimeResult.text}</time>
        </>
      );
      break;
    case "closed":
      openingTimeBlock = (
        <>
          <span class="rt-dayname">{dayNameStr_upperCase}</span>
          <time class="rt-opening-time">{translations.k_store_closed}</time>
        </>
      );
      break;
    case "error":
      console.warn(
        `unable to create opening time text for store ID=${store.id}`
      );
      break;
  }

  if (openingTimeBlock) {
    return openingTimeBlock;
  } else {
    return null;
  }
}

export function getStoreOpeningTime({
  dayIndex,
  store,
  format,
  hoursSeparator = "-",
  openingsSeparator = ",",
}: OpeningTimeProps): OpeningTimeResult {
  try {
    // opening time paris of the correct day
    let openingDay = store.openingHours[dayIndex];

    if (!openingDay) {
      // no info on the opening hours
      return { status: "unknown" };
    }

    if (openingDay.length === 0) {
      // store is closed
      return { status: "closed" };
    }

    let [openingPair1, openingPair2] = openingDay;

    // if our format is 24h we need to transform the data we have into 12h
    if (format === "12h") {
      openingPair1 = openingPair1.map(from24to12Hours) as OpeningHoursPair;
    }

    let openingText = `${openingPair1[0]} ${hoursSeparator} ${openingPair1[1]}`;

    if (openingPair2) {
      // if our format is 24h we need to transform the data we have into 12h
      if (format === "12h") {
        openingPair2 = openingPair2.map(from24to12Hours) as OpeningHoursPair;
      }
      openingText += ` ${openingsSeparator} ${openingPair2[0]} ${hoursSeparator} ${openingPair2[1]}`;
    }

    return { status: "ok", text: openingText };
  } catch (_) {
    return {
      status: "error",
      message: `Cannot create opening text for store id=${store.id}`,
    };
  }
}
