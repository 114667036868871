export class Trap_focus {
  public focusableElements: HTMLElement[];
  private trappingElement: HTMLElement;
  private firstFocusableEl: HTMLElement;
  private lastFocusableEl: HTMLElement;
  private triggerElement: HTMLElement | null;

  constructor(element: HTMLElement, triggerElementId: HTMLElement) {
    
    const focusableNodes = element.querySelectorAll<HTMLElement>(
      'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input:not([disabled]), select:not([disabled]), [tabindex="0"]:not([disabled])'
    );
    this.focusableElements = Array.from(focusableNodes);
    if(element.tabIndex === 0) {
      this.focusableElements.unshift(element);
    }
    this.trappingElement = element;
    this.firstFocusableEl = this.focusableElements[0];
    this.lastFocusableEl =
      this.focusableElements[this.focusableElements.length - 1];
    this.triggerElement = triggerElementId;
  }

  public trap() {
    this.firstFocusableEl.focus();
    this.trappingElement.onkeydown = (e: KeyboardEvent) => {
      let isTabPressed = e.key === "Tab" || e.keyCode === 9;

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
        if (document.activeElement === this.firstFocusableEl) {
          this.lastFocusableEl.focus();
          e.preventDefault();
        }
      } else {
        if (document.activeElement === this.lastFocusableEl) {
          this.firstFocusableEl.focus();
          e.preventDefault();
        }
      }
    };
  }

  public untrap() {
    if (this.triggerElement) {
      this.triggerElement.focus();
    } else {
      console.warn(
        `element with the id: ${this.triggerElement} does not exist in DOM`
      );
    }
  }

  public addFocusableElement(element: HTMLElement, startIndex?: number) {
    this.focusableElements.splice(
      startIndex ? startIndex : this.focusableElements.length,
      0,
      element
    );
    this.firstFocusableEl = this.focusableElements[0];
    this.lastFocusableEl =
      this.focusableElements[this.focusableElements.length - 1];
  }

  public removeFocusableElement(element: HTMLElement) {
    this.focusableElements = this.focusableElements.filter(
      (focusableEl) => focusableEl !== element
    );
    this.firstFocusableEl = this.focusableElements[0];
    this.lastFocusableEl =
      this.focusableElements[this.focusableElements.length - 1];
  }
}
