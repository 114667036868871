export const style = [
  {
    featureType: "poi",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#C5E3BF",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        lightness: 100,
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#D1D1B8",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#C6E2FF",
      },
    ],
  },
];
